.loaderComponent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-image: url('../../../shared/assets/images/all-state.png');
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactive {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 150ms linear;
  display: flex;
  background-image: none;
}

.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #bd0bd0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;

}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}

.wrapper {
  background-color: #D9D9D9C4;
  border-radius: 16px;
  margin: auto 50px;
  padding: 40px 32px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 24px;
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.textBold {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.link {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #000;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
