@import "reset";
@import "variables/global";

:root {
  min-height: var(--tg-viewport-height);
  max-height: var(--tg-viewport-height);

}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: var(--def-font-color);
  overflow: hidden;
  background-color: #000;
}


.app {
  display: flex;
  flex-direction: column;
  color: var(--def-font-color);
}

.background {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.background-image {
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  right: 0;
  bottom: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../shared/assets/images/background.png');
  background-position: top center;
  background-attachment: scroll;
}

.activeImgBackground {
  opacity: 1;
  visibility: visible;
}

.imageBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  display: block;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}

.app__container {
  position: relative;
  margin: 0 auto;
  min-width: 270px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  padding: 0 22px 30px;
}

.desktop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3000;
  background-color: #000000;
  display: none;
}

.desktopImageActive {
  opacity: 1;
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktopImage {
  opacity: 1;
  visibility: visible;
  z-index: 3000;
  width: 254px;
  height: 254px;
  object-fit: cover;


}


.textHeaderDesktop {
  margin-top: 32px;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: #fff;
  text-align: center;
  max-width: 240px;
}

.textBot {
  margin-top: 32px;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  text-decoration: underline;
  color: #fff;
}

