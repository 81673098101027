.errorHint {
  margin-top: 64px;
  border-radius: 32px;
  box-shadow: 0 4px 16.5px 0 rgba(154, 34, 20, 0.1);
  background: linear-gradient(162.41deg, #FFF1CE -0.59%, #FFFBF3 106.26%, #FFE39D 106.27%);
  padding: 20px;
}

.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.button {
  margin-top: 22px;
  outline: none;
  border: 2px solid rgb(255, 172, 62);
  border-radius: 15px;
  background: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  transition: 150ms ease;
  &:hover {
    background: rgba(255, 172, 62, 0.8);
    color: #fff;
  }
  &:active {
    background: rgba(255, 172, 62, 0.8);
    color: #fff;
  }
}